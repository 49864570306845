<template>
  <div>
    <div class="flex flex-wrap">
      <!-- <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart />
      </div> -->
      <!-- <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div> -->
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
        >
          <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-base text-blueGray-700">
                  Tabel Komentar
                </h3>
              </div>
              <!-- <div
                class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
              >
                <button
                  class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  See all
                </button>
              </div> -->
            </div>
          </div>
          <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  >
                    Pengirim
                  </th>
                  <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  >
                    Tanggal
                  </th>
                  <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  >
                    Rating
                  </th>
                  <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  >
                    Komentar
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="komentar in listKomentar" :key="komentar.id">
                  <th
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                  >
                    {{ komentar.pengirim }}
                  </th>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ formatDate(komentar.created_at) }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ komentar.rating }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ komentar.komentar }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination @current-page="currentPage" :pageSize="itemPage" :totalItem="totalKomentar"/>
      </div>
      <!-- <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-page-visits />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div> -->
    </div>
  </div>
</template>
<script>
import komentarServices from "../../services/komentar-services";
import { onMounted, onUpdated } from "vue";
import Pagination from "@/components/Pagination/Pagination.vue"
import moment from "moment"

export default {
  components: {
    Pagination,
  },
  setup() {
    const { itemPage, listKomentar, totalKomentar, getListKomentar } =
      komentarServices();

    onMounted(getListKomentar)

    const currentPage = (event) => {
      onUpdated(getListKomentar(event.value))
    }

    const formatDate = (date) => {
      return moment(date).lang("id").format("DD MMMM YYYY")
    }

    return {
      listKomentar,
      itemPage,
      totalKomentar,
      currentPage,
      formatDate
    };
  },
};
</script>
