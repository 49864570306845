<template>
  <div class="flex justify-end">
    <div class="py-2">
      <nav class="block">
        <ul class="flex pl-0 rounded list-none flex-wrap">
          <!-- <li>
            <a
              href="#pablo"
              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 text-white bg-emerald-500"
            >
              1
            </a>
          </li> -->
          <li>
            <a
              href="javascript:void(0)" @click="first() ; changePage()"
              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative shadow bg-white text-blueGray-800"
            >
                <i class="fas fa-fast-backward"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)" @click="prev() ; changePage()"
              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative shadow bg-white text-blueGray-800"
            >
                <i class="fas fa-step-backward"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)" @click="next() ; changePage()"
              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative shadow bg-white text-blueGray-800"
            >
                <i class="fas fa-step-forward"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)" @click="last() ; changePage()"
              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative shadow bg-white text-blueGray-800"
            >
            <i class="fas fa-fast-forward"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { computed } from "vue"
import { usePagination } from "vue-composable"

export default {
    emits: ["current-page"],
    props: {
        pageSize: {
          type: Number,
          default: 1
        },
        totalItem: {
            type: Number,
            default: 1
        }
    },

    setup(props, { emit }) {
      const { currentPage, next, prev, first, last } = usePagination({
        currentPage: 1,
        pageSize: 10,
        total: computed(() => props.totalItem)
      })

      const changePage = () => {
        emit("current-page", computed(() => currentPage.value))
      }

        // const next = () => {
        //   emit("current-page", computed(() => props.currentPage <= props.lastPage ? props.currentPage + 1 : props.lastPage))
        // }

        // const previous = () => {
        //   emit("current-page", computed(() => props.currentPage > 1 ? props.currentPage - 1 : 1))
        // }

        // const first = () => { 
        //   emit("current-page", computed(() => props.currentPage / props.currentPage ))
        // }

        // const last = () => {
        //   emit("current-page", computed(() => props.lastPage))
        // }

        return {
          next,
          prev,
          first,
          last,
          changePage
        }
    }
}
</script>
