import { ref } from "vue";
import http from "./http-common";

export default function atc5Services() {
    const listObat = ref([]);
    const totalObat = ref();
    const itemPage = ref();

    const getListObat = async (search = "", page = 1) => {
        let response = await http.get(`/obat?page=${page}&search=${search}`);
        listObat.value = response.data.data;
        totalObat.value = response.data.total;
        itemPage.value = response.data.per_page
    }

    const getObat = async (id) => {
        return await http.get(`/obat/${id}`)
    }

    const addObat = async (obat) => {
        await http.post('/obat', obat,{
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        });
    }

    const editObat = async (obat, id) => {
        await http.put(`/obat/${id}`, obat, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        })
    }

    const deleteObat = async (id) => {
        await http.delete(`/obat/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        });
    }
    
    return {
        listObat,
        totalObat,
        itemPage,
        getListObat,
        getObat,
        addObat,
        editObat,
        deleteObat
    }
}