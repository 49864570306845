<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">Form Interaksi</h6>
            <button
              class="bg-secondary-30 text-white active:bg-secondary-50 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              {{ title }}
            </button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form @submit.prevent="submitForm()">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informasi Interaksi
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Obat 1
                  </label>
                  <!-- <select
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="kode_1"
                  >
                    <option
                      v-for="data in listObat"
                      :key="data.kode"
                      :value="data.kode"
                    >
                      {{ data.nama }}
                    </option>
                  </select> -->
                  <div class="px-1 py-1 bg-white rounded shadow w-full">
                    <v-select
                      :options="listObat"
                      :reduce="(listObat) => listObat.kode"
                      label="nama"
                      v-model="kode_1"
                      @search="searchObat"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Obat <em>{{ search }}</em> tidak ditemukan
                        </template>
                        <em v-else style="opacity: 0.5"
                          >Ketik untuk mencari obat</em
                        >
                      </template>
                    </v-select>
                  </div>
                  <span class="text-red-500">{{ errors["kode_1"] }}</span>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Obat 2
                  </label>
                  <!-- <select
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="kode_2"
                  >
                    <option
                      v-for="data in listObat"
                      :key="data.kode"
                      :value="data.kode"
                    >
                      {{ data.nama }}
                    </option>
                  </select> -->
                  <div class="px-1 py-1 bg-white rounded shadow w-full">
                    <v-select
                      :options="listObat"
                      :reduce="(listObat) => listObat.kode"
                      label="nama"
                      v-model="kode_2"
                      @search="searchObat"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Obat <em>{{ search }}</em> tidak ditemukan
                        </template>
                        <em v-else style="opacity: 0.5"
                          >Ketik untuk mencari obat</em
                        >
                      </template>
                    </v-select>
                  </div>
                  <span class="text-red-500">{{ errors["kode_2"] }}</span>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Level Interaksi
                  </label>
                  <select
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="interaksi_kriteria_code"
                  >
                    <option
                      v-for="data in listKriteria"
                      :key="data.kode"
                      :value="data.kode"
                    >
                      {{ data.kriteria }}
                    </option>
                  </select>
                  <span class="text-red-500">{{
                    errors["interaksi_kriteria_code"]
                  }}</span>
                </div>
              </div>
            </div>

            <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

            <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Contact Information
                </h6> -->
            <!-- <div class="flex flex-wrap"></div> -->

            <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

            <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  About Me
                </h6> -->
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Deskripsi
                  </label>
                  <textarea
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                    placeholder="Deskripsi"
                    v-model="interaksi.deskripsi"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Deskripsi Tambahan
                  </label>
                  <textarea
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                    placeholder="Deskripsi Tambahan"
                    v-model="interaksi.deskripsi_tambahan"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mt-3">
                  <button
                    class="bg-secondary-30 text-white active:bg-secondary-50 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Edit Interaksi
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="w-full lg:w-4/12 px-4">
          </div> -->
  </div>
</template>
<script>
import { reactive, onMounted, computed, onUpdated } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useForm, useField, useFormErrors } from "vee-validate";
import * as yup from "yup";
import atc5Services from "../../../services/atc5-services";
import interaksiServices from "../../../services/interaksi-services";
import interaksiKriteriaServices from "../../../services/interaksi-kriteria-services";

export default {
  props: {
    title: {
      type: String,
      default: "Edit",
    },
  },
  components: {},
  setup() {
    let timer;
    // reactive form
    const interaksi = reactive({
      kode_1: null,
      kode_2: null,
      interaksi_kriteria_code: null,
      deskripsi: null,
      deskripsi_tambahan: null,
    });

    //router
    const router = useRouter();
    const route = useRoute();

    // id
    const interaksiId = computed(() => route.params.id);

    // servis obat
    const { listObat, getListObat } = atc5Services();

    // servis interaksi kriteria
    const { listKriteria, getAllKriteria } = interaksiKriteriaServices();

    // servis interaksi
    const { editInteraksi, getInteraksi } = interaksiServices();

    // get list obat
    onMounted([getListObat, getAllKriteria]);

    const searchObat = (search, loading) => {
      loading(true);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        onUpdated(getListObat(search));
        loading(false);
      }, 1500);
    };

    const schema = yup.object({
      kode_1: yup.string().required("Obat 1 wajib diisi!!"),
      kode_2: yup.string().required("Obat 2 wajib diisi!!"),
      interaksi_kriteria_code: yup
        .string()
        .required("Interaksi kriteria wajib diisi!!"),
    });

    const form = useForm({
      validationSchema: schema,
    });

    const errors = useFormErrors(form);

    const { value: kode_1 } = useField("kode_1");
    const { value: kode_2 } = useField("kode_2");
    const { value: interaksi_kriteria_code } = useField(
      "interaksi_kriteria_code"
    );

    onMounted(async () => {
      const resp = await getInteraksi(interaksiId.value);
      form.setFieldValue("kode_1", resp.data.kode_1);
      form.setFieldValue("kode_2", resp.data.kode_2);
      form.setFieldValue(
        "interaksi_kriteria_code",
        resp.data.interaksi_kriteria_code
      );
      // interaksi.kode_1 = resp.data.kode_1;
      // interaksi.kode_2 = resp.data.kode_2;
      // interaksi.interaksi_kriteria_code = resp.data.interaksi_kriteria_code;
      interaksi.deskripsi = resp.data.deskripsi;
      interaksi.deskripsi_tambahan = resp.data.deskripsi_tambahan;
    });

    const submitForm = async () => {
      const resp = await form.validate();
      if (resp.valid) {
        interaksi.kode_1 = kode_1.value;
        interaksi.kode_2 = kode_2.value;
        interaksi.interaksi_kriteria_code = interaksi_kriteria_code.value;
        await editInteraksi({ ...interaksi }, interaksiId.value);
        await router.push({
          name: "list-interaksi",
          query: { success: "edit" },
        });
      }
    };

    return {
      listKriteria,
      interaksi,
      listObat,
      kode_1,
      kode_2,
      interaksi_kriteria_code,
      errors,
      searchObat,
      submitForm,
    };
  },
};
</script>

<style scoped>
:deep() {
  --vs-border-width: 0px;
}
</style>
