<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="text-center m-3">
            <img src="../../assets/img/logo-smartdio.png" alt="logo-smartdio">
            <!-- <h6 class="text-red-500 text-xl font-bold">
              Logo Goes Here
            </h6> -->
          </div>
          <div v-if="error" class="text-white px-6 py-4 border-0 rounded relative mx-4 bg-red-500">
            <span class="text-xl inline-block mr-5 align-middle">
              <i class="fas fa-exclamation"></i>
            </span>
            <span class="inline-block align-middle">
              <b class="capitalize">{{ error }}</b>
            </span>
          </div>
          <!-- <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with
              </h6>
            </div>
            <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="github" />
                Github
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="google" />
                Google
              </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div> -->
          <!-- <div class="flex-auto px-4 lg:px-10 py-10 pt-0"> -->
          <div class="rounded-t mb-0 px-6 pb-6">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Silahkan masukkan username dan password</small>
            </div>
            <Form @submit="handleSubmit" :validationSchema="schema">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Username
                </label>
                <Field name="username"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Username"  />
              </div>
              <ErrorMessage name="username" class="text-red-500"/>
              <!-- <div>Username tidak boleh kosong</div> -->

              <div class="relative w-full mt-3 mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Password
                </label>
                <Field type="password" name="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"  />
              </div>
              <ErrorMessage name="password" class="text-red-500"/>
              <!-- <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div> -->

              <div class="text-center mt-6">
                <!-- <router-link to="/admin"> -->
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit">
                  Sign In
                </button>
                <!-- </router-link> -->
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import github from "@/assets/img/github.svg";
// import google from "@/assets/img/google.svg";
import http from "@/services/http-common";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: "Login",
  data() {
    // let user = {
    //   username: "",
    //   password: ""
    // }

    const schema = yup.object({
      username: yup.string().required("Username wajib diisi"),
      password: yup.string().required("Password wajib diisi"),
    });

    let error

    return {
      // user,
      error,
      schema
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    async handleSubmit(user) {
      try {
        const response = await http.post('/auth/login', user)

        sessionStorage.setItem('token', response.data.access_token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        // localStorage.setItem('token', response.data.access_token);
        // localStorage.setItem('user', JSON.stringify(response.data.user));
        this.$store.dispatch('login', response.data.user);
        this.$router.push('/admin');
      } catch (error) {
        this.error = "Username atau password salah"
      }
    }
  }
};
</script>
