<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">
              Detail Interaksi
            </h6>
            <button
              class="bg-secondary-30 text-white active:bg-secondary-50 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              {{ title }}
            </button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Informasi Interaksi
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3 text-center">
                <h1 class="text-blueGray-800 text-3xl">
                  {{ interaksi.obat1 }}  <i class="fas fa-infinity"></i>  {{ interaksi.obat2 }}
                  <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-900 bg-lightBlue-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'T'"
                    >
                      {{ interaksi.interaksi_kriteria }}
                    </span>
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-900 bg-emerald-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'R'"
                    >
                      {{ interaksi.interaksi_kriteria }}
                    </span>
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-800 bg-orange-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'S'"
                    >
                      {{ interaksi.interaksi_kriteria }}
                    </span>
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-800 bg-red-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'B'"
                    >
                      {{ interaksi.interaksi_kriteria }}
                    </span>
                </h1>
              </div>
            </div>
            <!-- <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3 text-center">
                    <h1 class="text-blueGray-800 text-3xl">{{ interaksi.obat1 }}</h1>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3 text-center">
                    <h1 class="text-blueGray-800 text-3xl">X</h1>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3 text-center">
                    <h1 class="text-blueGray-800 text-3xl">{{ interaksi.obat2 }}</h1>
                  </div>
                </div> -->
          </div>

          <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

          <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Contact Information
                </h6> -->
          <!-- <div class="flex flex-wrap"></div> -->

          <hr class="mt-6 border-b-1 border-blueGray-300" />

          <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  About Me
                </h6> -->
          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">
                  Deskripsi
                </h1>
                <p>
                  {{ interaksi.deskripsi }}
                </p>
              </div>
            </div>
          </div>

          <hr class="mt-6 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">
                  Deskripsi Tambahan
                </h1>
                <p>
                  {{ interaksi.deskripsi_tambahan }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full lg:w-4/12 px-4">
          </div> -->
  </div>
</template>
<script>
import { reactive, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import interaksiServices from "../../../services/interaksi-services";

export default {
  props: {
    title: {
      type: String,
      default: "Detail",
    },
  },
  components: {},
  setup() {
    const route = useRoute();
    const interaksiId = computed(() => route.params.id);
    // reactive form
    const interaksi = reactive({
      kode_1: null,
      kode_2: null,
      interaksi_kriteria_code: null,
      deskripsi: null,
      deskripsi_tambahan: null,
      obat1: null,
      obat2: null,
      interaksi_kriteria: null,
    });

    // servis interaksi
    const { getInteraksi } = interaksiServices();

    onMounted(async () => {
      const resp = await getInteraksi(interaksiId.value);
      interaksi.kode_1 = resp.data.kode_1;
      interaksi.kode_2 = resp.data.kode_2;
      interaksi.interaksi_kriteria_code = resp.data.interaksi_kriteria_code;
      interaksi.deskripsi = resp.data.deskripsi;
      interaksi.deskripsi_tambahan = resp.data.deskripsi_tambahan;
      interaksi.interaksi_kriteria = resp.data.interaksi_kriteria.kriteria;
      interaksi.obat1 = resp.data.obat1.nama;
      interaksi.obat2 = resp.data.obat2.nama;
    });

    return {
      interaksi,
    };
  },
};
</script>
