<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">Form Obat</h6>
            <button
              class="bg-secondary-30 text-white active:bg-secondary-50 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              {{ title }}
            </button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form @submit.prevent="submitForm()">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informasi Obat
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Kode Obat
                  </label>
                  <input
                    type="text" name="kode"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Kode Obat"
                    v-model="kode"
                  />
                  <span class="text-red-500">{{ errors['kode'] }}</span>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nama Obat
                  </label>
                  <input
                    type="text" name="nama"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Nama Obat"
                    v-model="nama"
                  />
                  <span class="text-red-500">{{ errors['nama'] }}</span>
                </div>
              </div>
            </div>

            <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

            <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Contact Information
              </h6> -->
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Mekanisme Kerja
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Mekanisme Kerja"
                    v-model="formValue.mekanisme_kerja"
                  />
                </div>
              </div>
              <!-- <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      City
                    </label>
                    <input
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value="New York"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value="United States"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Postal Code
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value="Postal Code"
                    />
                  </div>
                </div> -->
            </div>

            <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->

            <!-- <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                About Me
              </h6> -->
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Deskripsi
                  </label>
                  <textarea
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                    placeholder="Deskripsi"
                    v-model="formValue.deskripsi"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Indikasi
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Indikasi"
                    v-model="formValue.indikasi"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bentuk Sediaan Obat
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Bentuk Sediaan Obat"
                    v-model="formValue.bentuk_sediaan_obat"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Dosis
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Dosis"
                    v-model="formValue.dosis"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Kontra Indikasi
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Kontra Indikasi"
                    v-model="formValue.kontra_indikasi"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Peringatan
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Peringatan"
                    v-model="formValue.peringatan"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Efek Samping
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Efek Samping"
                    v-model="formValue.efek_samping"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Fornas
                  </label>
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 shadow"
                    v-model="formValue.is_formanas"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Ceklist jika termasuk
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Parent
                  </label>
                  <!-- <select
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="formValue.parent_code"
                  >
                    <option
                      v-for="data in parent"
                      :key="data.kode"
                      :value="data.kode"
                    >
                      {{ data.nama }}
                    </option>
                  </select> -->
                  <div class="px-1 py-1 bg-white rounded shadow w-full">
                    <v-select
                      :options="listAtc4"
                      :reduce="(listAtc4) => listAtc4.kode"
                      label="nama"
                      v-model="formValue.parent_code"
                      @search="searchAtc4"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Obat <em>{{ search }}</em> tidak ditemukan
                        </template>
                        <em v-else style="opacity: 0.5"
                          >Ketik untuk mencari obat</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mt-3">
                  <button
                    class="bg-secondary-30 text-white active:bg-secondary-50 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Tambahkan Obat
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="w-full lg:w-4/12 px-4">
        </div> -->
  </div>
</template>
<script>
import { reactive, computed, onMounted, onUpdated } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useField, useForm, useFormErrors } from "vee-validate"
import * as yup from 'yup';
import atc5Services from "../../../services/atc5-services";
import atc4Services from "../../../services/atc4-services";

export default {
  props: {
    title: {
      type: String,
      default: "Edit",
    },
  },
  components: {},
  setup() {
   let timer

    // reactive form
    const formValue = reactive({
      kode: "",
      nama: "",
      mekanisme_kerja: "",
      deskripsi: "",
      indikasi: "",
      bentuk_sediaan_obat: "",
      dosis: "",
      kontra_indikasi: "",
      peringatan: "",
      efek_samping: "",
      is_formanas: "",
      parent_code: "",
    });

    // router
    const router = useRouter();
    // route
    const route = useRoute();
    // get id obat
    const obatId = computed(() => route.params.id);
    // servis obat
    const { editObat, getObat } = atc5Services();

    // servis parent
    const { listAtc4, getAllAtc4 } = atc4Services()
    onMounted(getAllAtc4)
    
    const searchAtc4 = (search, loading) => {
      loading(true)
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
          onUpdated(getAllAtc4(search))
          loading(false)
      }, 1500);
    }

    // form schema
    const schema = yup.object({
      kode: yup.string().required("Kode wajib diisi!!"),
      nama: yup.string().required("Nama wajib diisi!!")
    })

    // form validation
    const form = useForm({
      validationSchema: schema
    })
    // errors handler
    const errors = useFormErrors(form)

    // validate field
    const { value: kode } = useField('kode')
    const { value: nama } = useField('nama')

    onMounted(async () => {
      const resp = await getObat(obatId.value);
      formValue.id = resp.data.id;
      form.setFieldValue('kode', resp.data.kode)
      form.setFieldValue('nama', resp.data.nama)
      // formValue.kode = resp.data.kode;
      // formValue.nama = resp.data.nama;
      formValue.mekanisme_kerja = resp.data.mekanisme_kerja;
      formValue.deskripsi = resp.data.deskripsi;
      formValue.indikasi = resp.data.indikasi;
      formValue.bentuk_sediaan_obat = resp.data.bentuk_sediaan_obat;
      formValue.dosis = resp.data.dosis;
      formValue.kontra_indikasi = resp.data.kontra_indikasi;
      formValue.peringatan = resp.data.peringatan;
      formValue.efek_samping = resp.data.efek_samping;
      formValue.is_formanas = computed(() => resp.data.is_formanas ? true : false)
      formValue.parent_code = resp.data.parent_code;
    });

    const submitForm = async () => {
      const resp = await form.validate()
      if (resp.valid) {
        formValue.kode = kode.value
        formValue.nama = nama.value
        await editObat({ ...formValue }, obatId.value);
        await router.push({ name: "list-obat", query: { success: "edit" } });
      }
    };

    return {
      parent,
      formValue,
      kode,
      nama,
      errors,
      listAtc4,
      searchAtc4,
      submitForm,
    };
  },
};
</script>

<style scoped>
:deep() {
  --vs-border-width: 0px;
}
</style>
