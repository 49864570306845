import http from './http-common'
import { ref } from 'vue'

export default function interaksiKriteriaServices() {
    const listKriteria = ref([])

    const getAllKriteria = async () => {
        const response = await http.get(`/interaksi/kriteria`)
        listKriteria.value = response.data
    }

    return {
        listKriteria,
        getAllKriteria
    }
}