import { createStore } from "vuex";

export default createStore({
    state: {
        user: JSON.parse(sessionStorage.getItem('user')) ?? null
    },
    getters: {
        user: (state) => {
            return state.user;
        }
    },
    mutations: {
        user(state, user){
            state.user = user;
        }
    },
    actions: {
        login(context, user) {
            context.commit('user', user);
        },
        logout(context) {
            context.commit('user', null);
        }
    },
    modules: {}
})