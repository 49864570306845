<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
        >
          <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <div
                  class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap"
                >
                  <h3 class="font-semibold text-lg text-blueGray-700">
                    List Obat
                  </h3>
                  <form
                    class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
                  >
                    <div class="relative flex w-full flex-wrap items-stretch">
                      <span
                        class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
                      >
                        <i class="fas fa-search"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Pencarian nama obat"
                        @keyup="searchObat"
                        v-model="search"
                        class="border-blueGray-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                      />
                    </div>
                  </form>
                  <router-link :to="{ name: 'add-obat' }">
                    <button
                      class="bg-indigo-500 text-white active:bg-indigo-600 text-m font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Nama
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Kode Obat
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Mekanisme kerja
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Deskripsi
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Bentuk Sediaan Obat
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="obat in listObat" :key="obat.id">
                  <th
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
                  >
                    {{ obat.nama }}
                  </th>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ obat.kode }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ obat.mekanisme_kerja }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ obat.deskripsi }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ obat.bentuk_sediaan_obat }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                  >
                    <TableDropdown :id="obat.id" :tabel="'obat'" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination @current-page="currentPage" :pageSize="itemPage" :totalItem="totalObat"/>
      </div>
    </div>
  </div>
</template>

<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import Pagination from "@/components/Pagination/Pagination.vue"
import { onMounted, onUpdated, ref } from "vue";
import { useRoute } from "vue-router";
import atc5Services from "../../../services/atc5-services";
import Swal from "sweetalert2";

export default {
  components: {
    TableDropdown,
    Pagination,
  },
  setup() {
    let timer
    const search = ref()
    const route = useRoute()
    // let search = ref("")
    const { listObat, totalObat, itemPage, getListObat, deleteObat } = atc5Services();

    // get all obat
    onMounted(getListObat);

    // search obat
    const searchObat = (val) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        onUpdated(getListObat(val.target.value));
      }, 1500);
    };

    // pagination handler
    const currentPage = (event) => {
      onUpdated(getListObat(search.value, event.value))
      // console.log(event.value);
    } 

    switch (route.query.success) {
      case "add":
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil Menambahkan Obat!",
        });
        break;
      case "edit":
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil Mengedit Obat!",
        });
        break;
      case "delete":
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil Menghapus Obat!",
        });
        break;
      default:
        break;
    }

    return {
      listObat,
      totalObat,
      itemPage,
      search,
      searchObat,
      deleteObat,
      currentPage
    };
  },
};
</script>
