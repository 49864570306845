import http from "./http-common";
import { ref } from "vue";
export default function komentarServices() {
    const listKomentar = ref([])
    const totalKomentar = ref()
    const itemPage = ref()

    const getListKomentar = async (page = 1) => {
        let response = await http.get(`/rating?page=${page}`)
        listKomentar.value = response.data.data
        totalKomentar.value = response.data.total
        itemPage.value = response.data.per_page
    }

    return {
        listKomentar,
        totalKomentar,
        itemPage,
        getListKomentar
    }
}