import { ref } from "vue"
import http from "./http-common"

export default function interaksiServices() {
    const listInteraksi = ref([])
    const totalInteraksi = ref()
    const itemPage = ref()

    const getListInteraksi = async (search = "", page = 1) => {
        let response = await http.get(`/interaksi?page=${page}&search=${search}`)
        listInteraksi.value = response.data.data
        totalInteraksi.value = response.data.total
        itemPage.value = response.data.per_page
    }

    const getInteraksi = async (id) => {
        return await http.get(`/interaksi/${id}`)
    }

    const addInteraksi = async (interaksi) => {
        await http.post(`/interaksi`, interaksi, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        })
    }

    const editInteraksi = async (data, id) => {
        await http.put(`/interaksi/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        })
    }

    const deleteInteraksi = async (id) => {
        await http.delete(`/interaksi/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        })
    }

    return {
        listInteraksi,
        totalInteraksi,
        itemPage,
        getListInteraksi,
        getInteraksi,
        addInteraksi,
        editInteraksi,
        deleteInteraksi
    }
}