import { createWebHistory, createRouter } from "vue-router";

// layouts
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout
import Dashboard from "@/views/admin/Dashboard.vue";

import ListInteraksi from "@/views/admin/interaksi/ListInteraksi.vue";
import AddInteraksi from "@/views/admin/interaksi/AddInteraksi.vue";
import ShowInteraksi from "@/views/admin/interaksi/ShowInteraksi.vue";
import EditInteraksi from "@/views/admin/interaksi/EditInteraksi.vue";
// import Settings from "@/views/admin/Settings.vue";
// import Tables from "@/views/admin/Tables.vue";
// import Maps from "@/views/admin/Maps.vue";

import ListObat from "@/views/admin/obat/ListObat.vue";
import AddObat from "@/views/admin/obat/AddObat.vue";
import ShowObat from "@/views/admin/obat/ShowObat.vue";
import EditObat from "@/views/admin/obat/EditObat.vue";

// views for Auth layout
import Login from "@/views/auth/Login.vue";
//import Register from "@/views/auth/Register.vue";

// views without layouts
import LandingPage from "@/views/LandingPage.vue"
//import Landing from "@/views/Landing.vue";
//import Profile from "@/views/Profile.vue";
//import Index from "@/views/Index.vue";

// routes
const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem('token')) {
        return next({
          name: 'login'
        })
      }

      return next()
    },
    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/list-obat",
        name: "list-obat",
        component: ListObat,
      },
      {
        path: "/admin/list-obat/add",
        name: "add-obat",
        component: AddObat,
      },
      {
        path: "/admin/list-obat/show/:id",
        name: "show-obat",
        component: ShowObat,
      },
      {
        path: "/admin/list-obat/edit/:id",
        name: "edit-obat",
        component: EditObat,
      },
      {
        path: "/admin/list-interaksi",
        name: "list-interaksi",
        component: ListInteraksi,
      },
      {
        path: "/admin/list-interaksi/add",
        name: "add-interaksi",
        component: AddInteraksi,
      },
      {
        path: "/admin/list-interaksi/show/:id",
        name: "show-interaksi",
        component: ShowInteraksi,
      },
      {
        path: "/admin/list-interaksi/edit/:id",
        name: "edit-interaksi",
        component: EditInteraksi,
      }
      // {
      //   path: "/admin/settings",
      //   component: Settings,
      // },
      // {
      //   path: "/admin/tables",
      //   component: Tables,
      // },
      // {
      //   path: "/admin/maps",
      //   component: Maps,
      // },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem('token')) {
        return next({
          name: 'dashboard'
        })
      }

      return next()
    },
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: Login,
      },
      // {
      //   path: "/auth/register",
      //   component: Register,
      // },
    ],
  },
  // {
  //   path: "/landing",
  //   component: Landing,
  // },
  // {
  //   path: "/profile",
  //   component: Profile,
  // },
  {
    path: "/",
    component: LandingPage
    //component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/admin" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router