<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="javascript:void(0)"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link :to="detail">
        <a
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Detail
        </a>
      </router-link>
      <router-link :to="edit">
        <a
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Edit
        </a></router-link
      >
      <a
        href="javascript:void(0)"
        @click="toggleDeleteAlert"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Delete
      </a>
      <!-- <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Something else here
      </a> -->
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { ref, computed } from "vue";
import Swal from "sweetalert2";
import atc5Services from "../../services/atc5-services";
import interaksiServices from "../../services/interaksi-services";
import { useRouter } from "vue-router";

export default {
  props: {
    id: String,
    tabel: String,
  },
  setup(props) {
    let dropdownPopoverShow = ref(false);
    let btnDropdownRef = ref(null);
    let popoverDropdownRef = ref(null);
    const router = useRouter();

    // servis obat
    const { deleteObat } = atc5Services();

    // servis interaksi
    const { deleteInteraksi } = interaksiServices();

    // toggle dropdown function
    const toggleDropdown = (event) => {
      event.preventDefault();
      if (dropdownPopoverShow.value) {
        dropdownPopoverShow.value = false;
      } else {
        dropdownPopoverShow.value = true;
        createPopper(btnDropdownRef.value, popoverDropdownRef.value, {
          placement: "bottom-start",
        });
      }
    };

    // toggle delete alert
    const toggleDeleteAlert = () => {
      Swal.fire({
        title: "Apakah kamu yakin?",
        text: "Data yang telah dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Hapus data",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          switch (props.tabel) {
            case "obat":
              await deleteObat(props.id);
              break;
            case "interaksi":
              await deleteInteraksi(props.id);
              break;
            default:
              break;
          }
          await router.push({ query: { success: "delete" } });
          await router.go();
        }
      });
    };

    const detail = computed(() => {
      switch (props.tabel) {
        case "obat":
          return { name: "show-obat", params: { id: props.id } };
        case "interaksi":
          return { name: "show-interaksi", params: { id: props.id } };
        default:
          return null;
      }
    });

    const edit = computed(() => {
      switch (props.tabel) {
        case "obat":
          return { name: "edit-obat", params: { id: props.id } };

        case "interaksi":
          return { name: "edit-interaksi", params: { id: props.id } };

        default:
          return null;
      }
    });

    return {
      toggleDropdown,
      toggleDeleteAlert,
      detail,
      edit,
      dropdownPopoverShow,
      btnDropdownRef,
      popoverDropdownRef,
    };
  },
};
</script>
