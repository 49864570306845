<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">Informasi Obat</h6>
            <button
              class="bg-secondary-30 text-white active:bg-secondary-50 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              {{ title }}
            </button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-3">
          <!-- <h5 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informasi Obat
            </h5> -->
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-3xl">{{ obat.nama }}</h1>
                <div class="flex flex-row mt-1">
                  <h1 class="text-blueGray-800 text-s font-bold">
                    {{ obat.kode }}
                  </h1>
                  <button v-if="obat.is_formanas == 1"
                    class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Fornas
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-2">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">
                  Mekanisme Kerja
                </h1>
                <p>{{ obat.mekanisme_kerja }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">Deskripsi</h1>
                <p>{{ obat.deskripsi }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">Indikasi</h1>
                <p>{{ obat.indikasi }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">
                  Bentuk Sediaan Obat
                </h1>
                <p>{{ obat.bentuk_sediaan_obat }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">Dosis</h1>
                <p>{{ obat.dosis }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">
                  Kontra Indikasi
                </h1>
                <p>{{ obat.kontra_indikasi }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">Peringatan</h1>
                <p>{{ obat.peringatan }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">
                  Efek Samping
                </h1>
                <p>{{ obat.efek_samping }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-1 border-b-1 border-blueGray-300" />

          <div class="flex flex-wrap mt-3">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <h1 class="text-blueGray-800 text-xl font-bold">Parent Code</h1>
                <p>{{ obat.parent_code }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full lg:w-4/12 px-4">
        </div> -->
  </div>
</template>
<script>
import { onMounted, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import atc5Services from "../../../services/atc5-services";

export default {
  props: {
    title: {
      type: String,
      default: "Detail",
    },
  },
  setup() {
    const route = useRoute();
    const obatId = computed(() => route.params.id);
    const obat = reactive({
      id: "",
      kode: "",
      nama: "",
      mekanisme_kerja: "",
      deskripsi: "",
      indikasi: "",
      bentuk_sediaan_obat: "",
      dosis: "",
      kontra_indikasi: "",
      peringatan: "",
      efek_samping: "",
      is_formanas: 0,
      parent_code: "",
    });

    const { getObat } = atc5Services();

    onMounted(async () => {
      const resp = await getObat(obatId.value);
      obat.id = resp.data.id;
      obat.kode = resp.data.kode;
      obat.nama = resp.data.nama;
      obat.mekanisme_kerja = resp.data.mekanisme_kerja;
      obat.deskripsi = resp.data.deskripsi;
      obat.indikasi = resp.data.indikasi;
      obat.bentuk_sediaan_obat = resp.data.bentuk_sediaan_obat;
      obat.dosis = resp.data.dosis;
      obat.kontra_indikasi = resp.data.kontra_indikasi;
      obat.peringatan = resp.data.peringatan;
      obat.efek_samping = resp.data.efek_samping;
      obat.is_formanas = resp.data.is_formanas;
      obat.parent_code = resp.data.parent_code;
    });

    return {
      obat,
    };
  },
};
</script>
