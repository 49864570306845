<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
        >
          <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <div
                  class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap"
                >
                  <h3 class="font-semibold text-lg text-blueGray-700">
                    List Interaksi
                  </h3>
                  <form
                    class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
                  >
                    <div class="relative flex w-full flex-wrap items-stretch">
                      <span
                        class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
                      >
                        <i class="fas fa-search"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Pencarian interaksi"
                        @keyup="searchInteraksi"
                        v-model="search"
                        class="border-blueGray-300 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                      />
                    </div>
                  </form>
                  <router-link :to="{ name: 'add-interaksi' }">
                    <button
                      class="bg-indigo-500 text-white active:bg-indigo-600 text-m font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Obat 1
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Obat 2
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Interaksi Level
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  >
                    Deskripsi
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="interaksi in listInteraksi" :key="interaksi.id">
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-bold"
                  >
                    {{ interaksi.obat1.nama }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-bold"
                  >
                    {{ interaksi.obat2.nama }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-900 bg-lightBlue-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'T'"
                    >
                      {{ interaksi.interaksi_kriteria.kriteria }}
                    </span>
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-900 bg-emerald-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'R'"
                    >
                      {{ interaksi.interaksi_kriteria.kriteria }}
                    </span>
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-800 bg-orange-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'S'"
                    >
                      {{ interaksi.interaksi_kriteria.kriteria }}
                    </span>
                    <span
                      class="text-xs items-center font-semibold inline-block py-1 px-2 uppercase rounded text-blueGray-800 bg-red-200 uppercase last:mr-0 mr-1"
                      v-if="interaksi.interaksi_kriteria_code === 'B'"
                    >
                      {{ interaksi.interaksi_kriteria.kriteria }}
                    </span>
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {{ interaksi.deskripsi }}
                  </td>
                  <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                  >
                    <TableDropdown :id="interaksi.id" :tabel="'interaksi'" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination @current-page="currentPage" :pageSize="itemPage" :totalItem="totalInteraksi"/>
      </div>
    </div>
  </div>
</template>

<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import interaksiServices from "../../../services/interaksi-services";
import { onMounted, onUpdated, ref } from "vue";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  components: {
    TableDropdown,
    Pagination
  },
  setup() {
    let timer
    const search = ref()
    // route
    const route = useRoute();

    const { listInteraksi, totalInteraksi, itemPage, getListInteraksi } = interaksiServices();

    onMounted(getListInteraksi);

    // search function
    const searchInteraksi = (val) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        onUpdated(getListInteraksi(val.target.value))
      }, 1500)
    }

    // pagination handler
    const currentPage = (event) => {
      onUpdated(getListInteraksi(search.value, event.value))
    }

    switch (route.query.success) {
      case "add":
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil Menambahkan Interaksi!",
        });
        break;
      case "edit":
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil Mengedit Interaksi!",
        });
        break;
      case "delete":
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil Menghapus Interaksi!",
        });
        break;
      default:
        break;
    }

    return {
      listInteraksi,
      totalInteraksi,
      itemPage,
      search,
      searchInteraksi,
      currentPage
    };
  },
};
</script>
