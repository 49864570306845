import http from './http-common'
import { ref } from 'vue'

export default function atc4Services() {
    const listAtc4 = ref([])

    const getAllAtc4 = async (search="") => {
        const response = await http.get(`/obat/parent?search=${search}`)
        listAtc4.value = response.data.data
    }

    return {
        listAtc4,
        getAllAtc4
    }
}