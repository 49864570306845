import { createApp } from "vue";
import App from "@/App.vue";
import router from './router'
import store from './store'

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// sweetalert 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// vue select
import VueSelect from "vue-select"
import "@/assets/styles/vue-select.css"
// import 'vue-select/dist/vue-select.css'



createApp(App).component('v-select', VueSelect).use(store).use(router).use(VueSweetalert2).mount("#app");
